var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "blog-post" },
    [
      !_vm.loading
        ? _c("BlogTopbar", {
            attrs: {
              categorySlug: _vm.post.category,
              goto: "blog-" + _vm.post.category,
            },
          })
        : _vm._e(),
      !_vm.loading
        ? _c(
            "div",
            { class: "post publicPage " + _vm.post.category },
            [
              _c("div", { attrs: { id: "postHero" } }, [
                _c("div", { staticClass: "postHero-image" }, [
                  _c("img", { attrs: { src: _vm.post.featuredMedia.src } }),
                ]),
                _c(
                  "div",
                  { staticClass: "postHero-content" },
                  [
                    _c("b-container", [
                      _c("div", { staticClass: "postHero-wrapper" }, [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                _vm.mixinGoTo("blog-" + _vm.post.category, {})
                              },
                            },
                          },
                          [
                            _c("BtnBack", {
                              attrs: { text: "Torna a " + _vm.title },
                            }),
                          ],
                          1
                        ),
                        _c("h1", { staticClass: "postHero-title" }, [
                          _vm._v(_vm._s(_vm.post.title)),
                        ]),
                        _c("p", { staticClass: "postDate" }, [
                          _vm._v(_vm._s(_vm.post.readableDate)),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("b-container", [
                _c("div", {
                  staticClass: "post__content",
                  domProps: { innerHTML: _vm._s(_vm.post.content) },
                }),
                _c("br"),
              ]),
              _c("b-container", { attrs: { fluid: "" } }, [
                _c("hr", { staticClass: "post-separator" }),
              ]),
              _vm.excludeId !== -1
                ? _c(
                    "b-container",
                    { attrs: { fluid: "" } },
                    [
                      _c("PostSlider", {
                        attrs: {
                          category: _vm.categoryId,
                          exclude: [_vm.excludeId],
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c("div", { staticClass: "post loading" }, [_c("Spinner")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }