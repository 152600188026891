<template>
    <div class="blog-post">
        <BlogTopbar
            v-if="!loading"
            :categorySlug="post.category"
            :goto="'blog-'+post.category"
        />
        <div :class="'post publicPage ' + post.category" v-if="!loading">
            <div id="postHero">
                <div class="postHero-image"><img :src="post.featuredMedia.src" /></div>
                <div class="postHero-content">
                    <b-container>
                        <div class="postHero-wrapper">
                            <div @click="mixinGoTo(('blog-'+post.category),{})"><BtnBack :text="'Torna a ' + title" /></div>
                            <h1 class="postHero-title">{{post.title}}</h1>
                            <p class="postDate">{{post.readableDate}}</p>
                        </div>
                    </b-container>
                </div>
            </div>
            <b-container>
                <div class="post__content" v-html="post.content"></div>
                <br>
            </b-container>
            
            <b-container fluid>
                <hr class="post-separator"/>
            </b-container>

            <b-container fluid v-if="excludeId !== -1">
                <PostSlider  :category="categoryId" :exclude="[excludeId]"/>
            </b-container>
        </div>
        <div class="post loading" v-else>
            <Spinner />
        </div>
    </div>
</template>

<script>
  //import Vue from 'vue'
  import Api from '@/api'
  import Spinner from '@/components/atoms/spinner'
  import PostSlider from '@/components/blocks/post-slider'
  import BlogTopbar from './blog-topbar.vue'
  import BtnBack from '@/components/atoms/btn-back'

  export default {
    name: 'post',
    metaInfo () {
      return {
        title: (this.meta.og_title)? this.meta.og_title : 'post',
        meta: [
          { vmid: 'robots', name: 'robots', content: this.metaRobots },

          (this.meta.og_title)?{ vmid: 'metaTitle', name: 'Title', content: this.meta.og_title } : {},

          (this.meta.og_description)?{ vmid: 'metaDescription', name: 'Description', content: this.meta.og_description } : {},

          (this.meta.og_url)?{ vmid: 'ogUrl', name: 'og:url', content: this.meta.og_url } : {},

          (this.meta.og_locale)?{ vmid: 'ogLocale', name: 'og:locale', content: this.meta.og_locale } : {},

          (this.meta.og_type)?{ vmid: 'ogType', name: 'og:type', content: this.meta.og_type } : {},

          (this.meta.og_title)?{ vmid: 'ogTitle', name: 'og:title', content: this.meta.og_title } : {},

          (this.meta.og_description)?{ vmid: 'ogDescription', name: 'og:description', content: this.meta.og_description } : {},

          (this.meta.og_image)? { vmid: 'ogImage', name: 'og:image', content: this.meta.og_image } : {},

          (this.meta.og_image_width)? { vmid: 'ogImageWidth', name: 'og:width', content: this.meta.og_image_width } : {},

          (this.meta.og_image_height)? { vmid: 'ogImageHeight', name: 'og:height', content: this.meta.og_image_height } : {},

        ]
      }
    },
    components: {
        Spinner,
        PostSlider,
        BlogTopbar,
        BtnBack,
    },
    props:
    {
        postId:
        {
            type: Number,
            default: 0,
        },
        slug:{
          type: String,
          default: '',
        }
    },
    data() {
        return {
            loading: true,
            post: {},
            meta: {}, // i meta ritornano dall'api dentro il post, ma li tiro fuori per accederci più facilmente
            metaRobots: process.env.VUE_APP_META_ROBOTS,
            categoryId: this.$config.posts.category,
            exclude: -1,
            title: '',
        }
    },
    watch: {
        postId: function () {
            this.getPost()
        },
        slug: function () {
          this.getPost()
        },
    },    
    mounted()
    {
        this.getPost()
    },
    methods:{
        getPost()
        {
            let _this = this
                _this.loading = true

            Api.getPosts({
                postId: _this.postId,
                slug: _this.slug,
            })
            .then((results) => {
                _this.loading = false

                if(results.posts){

                  if(_this.postId != 0){

                    // ricerca tramite prop postId
                    _this.post = results.posts[_this.postId]
                    _this.meta = (_this.post.meta)? _this.post.meta : {}
                    this.categoryId = _this.post.categoryId

                  }else{

                    // ricerca tramite prop slug
                    // l'api ritorna sempre una lista, prendo il primo disponibile
                    let postId = Object.keys(results.posts)[0]
                    _this.post = results.posts[postId]
                    _this.meta = (_this.post.meta)? _this.post.meta : {}
                    this.categoryId = _this.post.categoryId

                    // devo aggiornare il postId della prop per far funzionare "l'esclusione"
                    // del post corrente dallo slider
                    this.excludeId = _this.post.postId
                  }
                } else {
                  // se non ritorna, errore o post rimosso, setto noindex per deincizzare
                  _this.metaRobots = 'noindex'
                }
                
                switch (results.posts[_this.postId].category) {
                    case 'business':
                        this.title = 'BLOG - IN | Biz'
                        break;
                
                    default:
                        this.title = 'BLOG - La scelta dello staff'
                        break;
                }

            })
        }
    }
  }
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .blog-post
    {
        .postHero-wrapper
        {
            display:flex;
            flex-direction:column;
            align-content: flex-start;
            justify-content: flex-start;
            &>*
            {
                min-width:100%;
            }
            .btn-back
            {
                display:inline-flex;
                padding-bottom:2rem;
                svg
                {
                    margin-right:1rem !important;
                }
            }
        }

        .post
        {
            .post__content p
            {
                padding-top:0.05rem;
            }

            .post__content blockquote
            {
              padding-top:0.05rem;
              color: $orange;
              font-size: 1.5rem;
              text-align: center;
            }

            .post__content img
            {
            border-radius: 25px;
            //margin-bottom:1rem;
            }
            .alignleft
            {
                float: left;
                margin-right: 1.5em;
            }
            .alignright
            {
                float: right;
                margin-left: 1.5em;
            }
            .post__content
            {
                max-width: 830px;
                margin:auto;
                *
                {
                    font-family: $font-family-base !important;
                }
            }
            #postHero
            {
                display:flex;
                flex-direction: column;
                
                .postHero-slide
                {}

                .postHero-image
                {
                    flex:1;
                    min-width:100%;
                    position:relative;
                    &:after
                    {
                        content:"";
                        position:absolute;
                        right:0;
                        bottom:0;
                        width:50%;
                        height:12px;
                    }
                }

                .postHero-content
                {
                    flex:1;
                    min-width:100%;
                    margin:2rem auto;
                }

                .postHero-title
                {
                    color:$dark-blue;
                    font-size: 20px;
                    font-weight:900;
                    margin:0 auto;
                }

                .postDate
                {
                    display:block;
                    text-align:right;
                    max-width: 90%;
                    margin:auto;
                    margin-top:2rem;
                }

                .postHero-slide
                {}


                @include media-breakpoint-up(md)
                {
                    flex-direction: row;
                    margin-bottom:$grid-gutter-width;
                    margin-top:0;
                    .postHero-image
                    {
                        flex:50%;
                        min-width:50%;
                    }
                    .postHero-content
                    {
                        flex:50%;
                        min-width:50%;
                        .container
                        {
                            height:100%;
                            display:flex;
                            align-items: center;
                        }
                    }
                    .postHero-title
                    {
                        font-size: 26px;
                        max-width: 400px;
                    }
                }
                @include media-breakpoint-up(xl)
                {
                    .postHero-title
                    {
                        font-size: 30px;
                        max-width: 500px;
                    }
                }
                @include media-breakpoint-up(xxl)
                {
                    margin-bottom:$grid-gutter-width*1.5;
                    .postHero-title
                    {
                        font-size: 40px;
                        max-width: 700px;
                    }
                }
                img
                {
                    width:100%;
                }
            }

            .post-separator
            {
                border-top:1px solid $dark-gray;
                opacity:0.5;
                margin:$grid-gutter-width/2 0;
                @include media-breakpoint-up(md)
                {
                    margin:$grid-gutter-width/2 $grid-gutter-width/2 $grid-gutter-width $grid-gutter-width/2;
                }
            }
        }


        .consumer 
        {
            .postHero-title
            {
                color:$primary !important;
            }
            .postHero-image:after
            {
                background-color:$primary;
            }
            .post__content a,
            .post__content a:hover
            {
                color:$primary;
            }
        }

        .business 
        {
            .postHero-title
            {
                color:$dark-blue !important;
            }
            .postHero-image:after
            {
                background-color:$dark-blue;
            }
            .post__content a,
            .post__content a:hover
            {
                color:$dark-blue;
            }
        }
    }

</style>